import io from "socket.io-client";

const socket = io(`${process.env.VUE_APP_API}`, {
    secure: true,
    autoConnect: false,
    // withCredentials: true,
    // extraHeaders: {
    //   "Access-Control-Allow-Origin": true,
    // },
});
export function useSocketIO(me) {
    socket.auth = { me };
    return { socket };
}
