<template>
  <transition name="load">
    <div class="loading" v-if="active">
      <div class="layer"></div>
      <p>loading...</p>
    </div>
  </transition>
</template>

<script>
import { onMounted, watch, toRefs } from "vue";
export default {
  props: {
    active: {
      default: false,
    },
  },
  setup(props) {
    onMounted(() => {
      // document.body.style = "overflow-y:hidden";
    });
    const { active } = toRefs(props);
    watch(active, (newValue) => {
      if (!newValue) {
        document.body.style = "overflow-y:scroll";
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #29383a, $alpha: 0.6);
}
.loading {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  // mix-blend-mode: screen;
  // backdrop-filter: blur(3px);
  background: url("../assets/video/loading.gif") no-repeat center center;
  background-size: 300px;
  background-color: #31abdf;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin-top: 150px;
    color: white;
  }
}

.load-enter-active,
.load-leave-active {
  transition: 0.5s;
  opacity: 1;
}
.load-enter,
.load-leave-to {
  opacity: 0;
}
</style>
