<template>
    <router-view />
    <Toast></Toast>
</template>

<script>
import emitter from "@/methods/emitter";
import pushMessagesState from "@/methods/pushMessagesState";
import { provide } from "@vue/runtime-core";
import Toast from "@/components/ToastMessages.vue";

export default {
    components: { Toast },
    setup() {
        provide("emitter", emitter);
        provide("pushMessagesState", pushMessagesState);
        //safari瀏覽器禁止縮放
        window.onload = function () {
            document.addEventListener("touchstart", function (event) {
                if (event.touches.length > 1) {
                    event.preventDefault();
                }
            });
            var lastTouchEnd = 0;
            document.addEventListener(
                "touchend",
                function (event) {
                    var now = new Date().getTime();
                    if (now - lastTouchEnd <= 300) {
                        event.preventDefault();
                    }
                    lastTouchEnd = now;
                },
                false
            );
            document.addEventListener("gesturestart", function (event) {
                event.preventDefault();
            });
        };
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        //當旋轉螢幕vh發生變化，重新定義1vh為多少px
        window.addEventListener("resize", () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
        });
    },
};
</script>
