<template>
  <div class="chatbox">
    <div class="friends_title">
      <p>聯絡人</p>
    </div>
    <ul class="friends_list">
      <li
        v-for="(item, index) in friends"
        :key="item"
        :class="{ active: active === item.roomID }"
        @click="openChatRoom(item, index)"
      >
        <div class="left">
          <p v-if="!item.avatar && item.name">{{ item.name.slice(0, 1) }}</p>
          <img v-if="item.avatar" :src="item.avatar" alt="avatar" />
        </div>
        <div class="center">
          <h3>{{ item.name }}</h3>
          <p v-if="item.type === 'text'">{{ item.chat }}</p>
          <p v-if="item.type === 'image'">[圖片]</p>
        </div>
        <div class="right">
          <p v-if="item.timegap">{{ item.timegap }}</p>
          <div class="unread" v-if="item.unread">
            <p>{{ item.unread }}</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <!-- <router-view></router-view> -->
</template>

<script>
import { useSocketIO } from "@/service/socket";
import pushMessagesState from "@/methods/pushMessagesState";
import { inject, ref, watch } from "vue";
import { useStore } from "@/store/index";
import { storeToRefs } from "pinia";
import { timeGapFormat } from "@/methods/timeFormat";
export default {
  setup() {
    const axios = inject("axios");
    const emitter = inject("emitter");
    const store = useStore();
    const { friend, me, friends, page, isLoading, nowTime } =
      storeToRefs(store);

    let socket1;

    watch(
      () => me.value,
      () => {
        getFriends();
      }
    );

    function getFriends() {
      friends.value.length = 0;
      const api = `${process.env.VUE_APP_API}/api/user/friend/all`;
      axios.get(api).then((res) => {
        // Object.assign(friends, res.data.data);
        if (res.data.success) {
          friends.value = res.data.data;
          friends.value.forEach((item) => {
            let roomID =
              me.value.id > item.id
                ? item.id + "_" + me.value.id
                : me.value.id + "_" + item.id;
            item.roomID = roomID;
            if (item.avatar) {
              const url = `${process.env.VUE_APP_API}/user/picture?path=${item.avatar}`;
              item.avatar = url;
            }
          });
          getLastChat();
        } else {
          isLoading.value = false;
        }
      });
    }

    function getLastChat() {
      let fids = [];
      friends.value.forEach((item) => {
        fids.push(item.id);
      });
      const api = `${process.env.VUE_APP_API}/api/user/friend/all/lastchat`;
      axios.post(api, fids).then((res) => {
        let data = res.data.data;
        if (!data) return;
        data.forEach((item, index) => {
          if (item) {
            friends.value[index].chat = item[0].chat;
            friends.value[index].createTime = item[0].createTime;
            friends.value[index].timegap = timeGapFormat(
              Date.now() - item[0].createTime
            );
            friends.value[index].type = item[0].type;
            friends.value[index].unread = item[0].unread;
          }
        });
        friends.value.sort((a, b) => {
          return b.createTime - a.createTime;
        });
      });
      isLoading.value = false;
      if (!socket1) socketStart();
    }

    const active = ref("");
    watch(
      () => page.value,
      () => {
        if (page.value === 2) active.value = "";
      }
    );

    watch(
      () => nowTime.value,
      () => {
        friends.value.forEach((item) => {
          if (item.createTime) {
            item.timegap = timeGapFormat(nowTime.value - item.createTime);
          }
        });
      }
    );

    function socketStart() {
      // let fids = [];
      // friends.value.forEach((item) => {
      //   fids.push(item.id);
      // });
      let { socket } = useSocketIO(me.value);
      socket1 = socket;
      socket.connect();
      socket.on("last-message", (data) => {
        console.log(data);
        friends.value.forEach((item, index) => {
          if (item.roomID && item.roomID === data.roomID) {
            friends.value[index].chat = data.chat;
            friends.value[index].createTime = data.createTime;
            friends.value[index].timegap = "剛剛";
            friends.value[index].type = data.type;
            if (friend.value.roomID !== item.roomID) {
              friends.value[index].unread++;
            }
            //收取到單筆的最後一筆 將這筆從陣列取出在插入到第一筆
            let temp = friends.value.splice(index, 1);
            friends.value.unshift(temp[0]);
          }
          // if (!("Notification" in window)) {
          //   emitter.emit("push-message", {
          //     style: "danger",
          //     title: "此瀏覽器不支援Notification",
          //   });
          // } else {
          //   Notification.requestPermission().then((res) => {
          //     if (document.hasFocus()) {
          //       return;
          //     }
          //     if (res === "granted") {
          //       const notification = new Notification("海聊", {
          //         body: "有人傳訊息給你",
          //         icon: require("../assets/notification.png"),
          //       });
          //       notification.addEventListener("click", () => {
          //         window.open("http://localhost:8080");
          //       });
          //     }
          //   });
          // }
        });
        // friends.value.sort((a, b) => {
        //   return b.createTime - a.createTime;
        // });
      });
      socket.on("new-friend", () => {
        getFriends();
        pushMessagesState({ data: { success: true } }, "新好友添加你");
      });
    }

    emitter.on("push-friend", (id) => {
      getFriends();
      socket1.emit("add-friend", id);
    });

    function openChatRoom(user) {
      socket1.emit("leave-chat", friend.value.roomID);
      friend.value = user;
      page.value = 3;
      active.value = user.roomID;
      user.unread = 0;
    }

    return {
      friends,
      me,
      active,
      openChatRoom,
    };
  },
};
</script>

<style lang="scss">
.chatbox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .friends_title {
    height: 3rem;
    background-color: rgba($color: $color6, $alpha: 0.7);
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    box-shadow: 0 1px 8px -5px #fff;
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
  }
  .friends_list {
    height: calc(100% - 6rem);
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: rgba($color: $color6, $alpha: 0.4);
    .active {
      background-color: rgba($color: $color6, $alpha: 0.4);
    }
    li {
      // margin: 1rem 0;
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;
      min-height: 3.5rem;
      cursor: pointer;
      position: relative;
      border-bottom: 1px solid $color2;
      &:last-child {
        border-bottom: none;
      }
      .left {
        p {
          width: 3.5rem;
          height: 3.5rem;
          color: white;
          border-radius: 50%;
          background-color: $color5;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.5rem;
        }
        img {
          width: 3.5rem;
          height: 3.5rem;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .center {
        overflow-y: hidden;
        margin: 0 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
        color: $color1;
        max-width: 100%;
        overflow-x: hidden;
        h3 {
          font-size: 1.2rem;
          color: white;
        }
        p {
          margin-top: 0.5rem;
        }
      }
      .right {
        height: 100%;
        color: $color1;
        white-space: nowrap;
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .unread {
          background-color: $color7;
          border-radius: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 1rem;
          margin-right: 1rem;
          height: 1.5rem;
          padding: 0.5rem;
          p {
            color: white;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .chatbox {
    .friends_list {
      height: calc(100% - 3rem);
    }
  }
}

@keyframes openbeep {
  from,
  to {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}
</style>
