import { createRouter, createWebHashHistory } from "vue-router";
import callRooms from "../views/CallRooms.vue";
import callAudio from "../views/CallAudio.vue";
import callVideo from "../views/CallVideo.vue";
import callSharing from "../views/CallSharing.vue";
import UserSet from "../views/UserSet.vue";
import ChatRooms from "../views/ChatRooms.vue";
import ChatRoom from "../views/ChatRoom.vue";

const routes = [
    {
        path: "/",
        redirect: { name: "chatRooms" },
    },
    {
        path: "/userLogin",
        name: "userLogin",
        component: () => import("../views/UserLogin.vue"),
        meta: { title: "登入" },
    },
    {
        path: "/user",
        name: "user",
        component: () => import("../views/UserView.vue"),
        children: [
            {
                path: "chat",
                name: "chat",
                component: () => import("../views/ChatView.vue"),
                meta: { title: "聊天室" },
                children: [
                    {
                        path: "chatRooms",
                        name: "chatRooms",
                        components: {
                            view1: UserSet,
                            view2: ChatRooms,
                            view3: ChatRoom,
                            view4: callRooms,
                            view5: callVideo,
                        },
                    },
                ],
            },
            {
                path: "call",
                name: "call",
                component: callRooms,
                meta: { title: "通話" },
            },
            {
                path: "callAudio",
                name: "callAudio",
                component: callAudio,
            },
            {
                path: "callVideo",
                name: "callVideo",
                component: callVideo,
            },
            {
                path: "callSharing",
                name: "callSharing",
                component: callSharing,
            },
        ],
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});

export default router;
