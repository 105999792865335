<template>
  <div class="callbox">
    <audio src="" ref="audio" class="beep"></audio>
    <div class="friends_title">
      <p>通話，看影片</p>
    </div>
    <ul class="friends_list">
      <li v-for="item in friends" :key="item">
        <div class="left">
          <p v-if="!item.avatar && item.name">{{ item.name.slice(0, 1) }}</p>
          <img
            v-if="item.avatar"
            :src="item.avatar"
            alt="avatar"
            @click="openBigImg(true, item.avatar)"
          />
        </div>
        <div class="center">
          <h3>{{ item.name }}</h3>
          <p>通話時間</p>
        </div>
        <div class="right">
          <button class="call_sharing"></button>
          <button class="call_video" @click="openCallVideo(item)"></button>
          <button class="call_audio"></button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { useStore } from "@/store/index";
import { storeToRefs } from "pinia";
import { useSocketIO } from "@/service/socket";
import { watch } from "vue";

export default {
  setup() {
    const store = useStore();
    const { me, callFriend, friends, page, bigImgShow, bigImgUrl, callRole } =
      storeToRefs(store);

    watch(
      () => me.value,
      () => {
        socketStart();
      }
    );
    let socket1;
    function socketStart() {
      let { socket } = useSocketIO(me.value);
      socket1 = socket;
      socket.on("call-in", (user) => {
        if (callFriend.value.id) {
          return;
        }
        callRole.value = "answer";
        console.log(user.id + "打給你");
        callFriend.value = user;
        page.value = 5;
      });
    }
    function openCallVideo(user) {
      if (callFriend.value.id) {
        return;
      }
      socket1.emit("call-out", { fid: user.id, me: me.value });
      callRole.value = "offer";
      callFriend.value = user;
      page.value = 5;
    }

    function openBigImg(show, url) {
      bigImgShow.value = show;
      bigImgUrl.value = url;
    }

    return {
      friends,
      openCallVideo,
      openBigImg,
    };
  },
};
</script>

<style lang="scss">
.callbox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .friends_title {
    height: 3rem;
    background-color: rgba($color: $color6, $alpha: 0.7);
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    box-shadow: 0 1px 8px -5px #fff;
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
  }
  .friends_list {
    height: calc(100% - 6rem);
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: rgba($color: $color6, $alpha: 0.4);
    .active {
      background-color: rgba($color: $color6, $alpha: 0.4);
    }
    li {
      // margin: 1rem 0;
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;
      min-height: 3.5rem;
      cursor: pointer;
      border-bottom: 1px solid $color2;
      &:last-child {
        border-bottom: none;
      }
      .left {
        p {
          width: 3.5rem;
          height: 3.5rem;
          color: white;
          border-radius: 50%;
          background-color: $color5;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.5rem;
        }
        img {
          width: 3.5rem;
          height: 3.5rem;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .center {
        width: 100%;
        margin: 0 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        color: $color1;

        h3 {
          font-size: 1.2rem;
          color: white;
        }
        p {
          margin-top: 0.5rem;
        }
      }
      .right {
        height: 100%;
        display: flex;
        align-items: center;
        button {
          width: 1.6rem;
          height: 1.6rem;
          margin: 0 0.6rem;
          border: none;
        }
        .call_sharing {
          background: url("@/assets/sharing_w.svg") no-repeat center center;
          background-size: contain;
        }
        .call_video {
          background: url("@/assets/video_w.svg") no-repeat center center;
          background-size: contain;
        }
        .call_audio {
          background: url("@/assets/call_w.svg") no-repeat center center;
          background-size: contain;
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .callbox {
    .friends_list {
      height: calc(100% - 3rem);
    }
  }
}
</style>
