<template>
  <div class="chatroom">
    <div class="message_title">
      <button :disabled="!friend.id" @click="closeChatRoom"></button>
      <p v-if="friend">{{ friend.name }}</p>
    </div>
    <div class="message_content" ref="content">
      <Loading :active="isLoading"></Loading>
      <div
        class="user1"
        v-for="chat in chatList"
        :key="chat"
        :class="{ user_me: chat.user.name === me.id }"
      >
        <div class="chat_item">
          <div class="avatar" v-if="friend && chat.user.name !== me.id">
            <p v-if="!friend.avatar && friend.name">
              {{ friend.name.slice(0, 1) }}
            </p>
            <img
              v-if="friend.avatar"
              :src="friend.avatar"
              alt="avatar"
              @click="openBigImg(true, friend.avatar)"
            />
          </div>

          &nbsp;&nbsp;
          <div class="text_message" v-if="chat.type === 'text'">
            <span class="user_time">{{ chat.timegap }}</span>
            <p>{{ chat.message }}</p>
          </div>
          <div class="image_message" v-if="chat.type === 'image'">
            <span class="user_time">{{ chat.timegap }}</span>
            <img
              v-if="chat.imgUrl"
              :src="chat.imgUrl"
              alt=""
              @click="openBigImg(true, chat.imgUrl)"
            />
            <div v-else class="message_loading"></div>
          </div>
          &nbsp; &nbsp;
          <div class="avatar" v-if="friend && chat.user.name === me.id">
            <p v-if="!me.avatar && me.name">
              {{ me.name.slice(0, 1) }}
            </p>
            <img
              v-if="me.avatar"
              :src="me.avatar"
              alt="avatar"
              @click="openBigImg(true, me.avatar)"
            />
            <span>{{ chat.isRead ? "已讀" : "" }}</span>
          </div>
        </div>
      </div>
    </div>
    <form class="message_input" @submit.prevent="sendMsg">
      <input
        class="inputPic"
        type="file"
        ref="file"
        id="openPic"
        accept=".jpg,.png,.jpeg,.gif"
        @change="uploadFile"
        :disabled="!friend.id || !canSend"
      />
      <label for="openPic" class="labelPic"></label>
      <input
        type="text"
        v-model="chatMsg"
        placeholder=""
        :disabled="!friend.id"
      />
      <button type="submit" :disabled="!friend.id || !canSend"></button>
    </form>
  </div>
</template>

<script>
import { useSocketIO } from "@/service/socket";
import { inject, nextTick, ref, watch } from "vue";
import { useStore } from "@/store/index";
import { storeToRefs } from "pinia";
import Loading from "@/components/LoadingComponent.vue";
import { timeDateFormat } from "@/methods/timeFormat";

export default {
  components: { Loading },
  setup() {
    const store = useStore();
    const { friend, me, chatList, page, bigImgShow, bigImgUrl } =
      storeToRefs(store);
    const axios = inject("axios");

    let socket1;
    watch(
      () => friend.value,
      () => {
        if (me.value && Object.keys(friend.value).length) {
          if (!socket1) {
            socketStart();
          }
          isLoading.value = true;

          socket1.emit("join-chat", { id: me.id, fid: friend.value.id });
        }
      }
    );
    const isLoading = ref(false);

    const chatMsg = ref("");
    const content = ref(null);

    function socketStart() {
      let { socket } = useSocketIO(me.value);

      socket1 = socket;

      // console.log(socket.id, "監聽客戶端連接成功-connect");
      // socket.emit("join-chat", { id });

      socket.on("all-message", async (data) => {
        chatList.value = data;
        chatList.value.forEach((item) => {
          //第一次資料庫取出時時間是String
          item.timegap = timeDateFormat(parseInt(item.createTime));
          if (item.type === "image") {
            let url = `${process.env.VUE_APP_API}/user/picture?path=${item.message}`;
            item.imgUrl = url;
          }
        });
        await nextTick();
        if (content.value) {
          content.value.scrollTop =
            content.value.scrollHeight -
            document.scrollingElement.scrollHeight / 2;
        }
        socket1.emit("read", friend.value.roomID);

        isLoading.value = false;
      });
      socket.on("new-message", async (data) => {
        if (data.type === "image") {
          const url = `${process.env.VUE_APP_API}/user/picture?path=${data.message}`;
          data.imgUrl = url;
        }
        data.timegap = timeDateFormat(parseInt(data.createTime));
        chatList.value.push(data);

        await nextTick();
        if (content.value) {
          content.value.scrollTo({
            behavior: "smooth",
            top:
              content.value.scrollHeight -
              document.scrollingElement.scrollHeight / 2,
          });
        }
        socket1.emit("read", friend.value.roomID);
      });
      socket.on("readed", () => {
        chatList.value.forEach((item) => {
          item.isRead = 1;
          //第一次資料庫取出時時間是String
          item.timegap = timeDateFormat(parseInt(item.createTime));
        });
      });
    }

    // watch(
    //   () => nowTime.value,
    //   () => {
    //     chatList.value.forEach((item) => {
    //       item.timegap = timeDateFormat(item.createTime);
    //     });
    //   }
    // );
    let canSend = ref(true);
    const sendMsg = () => {
      canSend.value = false;
      if (!canSend.value) {
        setTimeout(() => {
          canSend.value = true;
        }, 500);
      }
      if (chatMsg.value.trim().length !== 0) {
        socket1.emit("send-message", { msg: chatMsg.value, type: "text" });
      }
      chatMsg.value = "";
    };

    const file = ref(null);
    function uploadFile() {
      const uploadedFile = file.value.files[0];
      const formData = new FormData();
      formData.append("picture", uploadedFile);

      //上傳imgur圖床
      /* const api = "https://api.imgur.com/3/image";
      axios({
        method: "POST",
        url: api,
        data: formData,
        headers: {
          Authorization: "Client-ID 16c6f281573b53f",
        },
      }).then((res) => {
        const imgUrl = res.data.data.link;
        socket1.emit("send-message", { msg: imgUrl, type: "image" });
      }); */

      const api = `${process.env.VUE_APP_API}/api/user/chatPic`;
      axios
        .post(api, formData, {
          Headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          if (res.data.success) {
            const imgUrl = res.data.data;
            socket1.emit("send-message", { msg: imgUrl, type: "image" });
          }
        });
      file.value.value = "";
    }

    function closeChatRoom() {
      socket1.emit("leave-chat", friend.value.roomID);
      // socket1.off("all-message");
      // socket1.off("readed");
      page.value = 2;
      setTimeout(() => {
        friend.value = {};
        chatList.value = [];
      }, 300);
    }
    function openBigImg(show, url) {
      bigImgShow.value = show;
      bigImgUrl.value = url;
    }

    return {
      friend,
      chatList,
      chatMsg,
      canSend,
      sendMsg,
      content,
      me,
      file,
      uploadFile,
      closeChatRoom,
      isLoading,
      openBigImg,
    };
  },
};
</script>

<style lang="scss">
.chatroom {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .message_title {
    height: 3rem;
    background-color: rgba($color: $color6, $alpha: 0.7);
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    box-shadow: 0 1px 8px -5px #fff;
    z-index: 0;
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
    button {
      position: absolute;
      left: 1rem;
      background: url("../assets/arrowleft_w.svg") no-repeat center center;
      background-size: contain;
      width: 2rem;
      height: 2rem;
      border: none;
    }
  }
  .message_content {
    padding: 1rem;
    height: calc(100% - 6rem);
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: rgba($color: $color6, $alpha: 0.2);
    color: white;
    .user1 {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      .chat_item {
        display: flex;
        align-items: flex-start;
        .avatar {
          p {
            min-width: 2.5rem;
            min-height: 2.5rem;
            color: white;
            border-radius: 50%;
            background-color: $color5;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.3rem;
          }
          img {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            object-fit: cover;
          }
          span {
            margin-top: 0.5rem;
            display: block;
            font-size: 0.8rem;
            color: $color1;
          }
        }

        .text_message {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-top: 0.3rem;
          width: 13.6rem;
          .user_time {
            font-size: 0.5rem;
            color: $color1;
          }
          p {
            border-radius: 10px;
            // background-color: $color9;
            background-color: rgba($color: $color6, $alpha: 0.5);
            border: 1px solid gray;
            padding: 0.8rem;
            word-break: break-all;
            margin: 0.5rem 0;
            border-top-left-radius: 0;
          }
        }
        .image_message {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-top: 0.3rem;
          width: 13.6rem;
          .user_time {
            font-size: 0.5rem;
            color: $color1;
          }
          .message_loading {
            width: 200px;
            height: 100px;
            border-radius: 10px;
            margin: 0.5rem 0;
            background-color: #fff;
          }
          img {
            object-fit: cover;
            width: 200px;
            height: 100px;
            border-radius: 10px;
            margin: 0.5rem 0;
          }
        }
      }
    }
    .user_me {
      align-items: flex-end;
      .chat_item {
        .text_message {
          align-items: flex-end;
          p {
            border-radius: 10px;
            border-top-right-radius: 0;
          }
        }
        .image_message {
          align-items: flex-end;
        }
      }
    }
  }
  .message_input {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 -1px 8px -5px #fff;
    background-color: rgba($color: $color3, $alpha: 1);
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 1;
    .inputPic {
      display: none;
    }
    .labelPic {
      width: 2rem;
      height: 2rem;
      border: none;
      background: url("../assets/avatar_w.svg") no-repeat center center;
      background-size: contain;
      cursor: pointer;
      margin-left: 2rem;
    }
    input {
      width: 70%;
      height: 80%;
      outline: none;
      border: none;
      padding-left: 1rem;
      background-color: $color2;
      color: white;
      border-radius: 5px;
      margin: 0 2rem;
    }
    button {
      border: none;
      background: url("../assets/send_w.svg") no-repeat center center;
      background-size: contain;
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      margin-right: 1rem;
    }
  }
}
</style>
