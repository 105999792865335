<template>
  <div class="userbox">
    <div class="user_title">
      <p>使用者</p>
    </div>
    <ul class="user_data">
      <li>
        <div class="avatar">
          <p v-if="!me.avatar && me.name">
            {{ me.name.slice(0, 1) }}
          </p>
          <img
            v-if="me.avatar"
            :src="me.avatar"
            alt="avatar"
            @click="openBigImg(true, me.avatar)"
          />
          <input
            type="file"
            ref="file"
            id="openAvatar"
            accept=".jpg,.png,.jpeg,.gif"
            @change="uploadFile"
          />
          <label for="openAvatar"></label>
        </div>
      </li>
      <li>
        <span> 使用者ID </span>
        <div class="right">
          <p>{{ me.id }}</p>
        </div>
      </li>
      <li>
        <span> 暱稱 </span>
        <div class="right">
          <p v-if="!openSetting">{{ me.name }}</p>
          <input
            type="text"
            v-if="openSetting"
            v-model="me.name"
            ref="newNickname"
          />
          <button
            class="set_name"
            @click="openSetting = true"
            v-if="!openSetting"
          ></button>
          <button
            class="confirm"
            @click="settingUser(me)"
            v-if="openSetting"
          ></button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, inject, watch } from "vue";
import { useStore } from "@/store/index";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const axios = inject("axios");
    const pushMessagesState = inject("pushMessagesState");
    const store = useStore();
    const { me, bigImgShow, bigImgUrl } = storeToRefs(store);
    getme();
    async function getme() {
      const api = `${process.env.VUE_APP_API}/api/user/me`;
      let res = await axios.get(api);
      if (!res.data.success) return;

      me.value = res.data.data[0];
      if (me.value.avatar) {
        const url = `${process.env.VUE_APP_API}/user/picture?path=${me.value.avatar}`;
        me.value.avatar = url;
      }
    }

    const openSetting = ref(false);
    const newNickname = ref(null);
    watch(newNickname, () => {
      if (newNickname.value) {
        newNickname.value.focus();
      }
    });
    async function settingUser(user) {
      let nickname = user.name;
      const api = `${process.env.VUE_APP_API}/api/user/setting`;
      let res = await axios.put(api, { nickname });

      if (res.data.success) {
        openSetting.value = false;
      }
      pushMessagesState(res, "改名");
    }

    const file = ref(null);
    function uploadFile() {
      const uploadedFile = file.value.files[0];
      const formData = new FormData();
      formData.append("avatar", uploadedFile);
      const api = `${process.env.VUE_APP_API}/api/user/avatar`;
      axios
        .put(api, formData, {
          Headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            // getme();
            const url = `${process.env.VUE_APP_API}/user/picture?path=${res.data.data}`;
            me.value.avatar = url;
          }
          pushMessagesState(res, "上傳頭相");
        });
      file.value.value = "";
    }
    function openBigImg(show, url) {
      bigImgShow.value = show;
      bigImgUrl.value = url;
    }
    return {
      me,
      openSetting,
      newNickname,
      settingUser,
      file,
      uploadFile,
      openBigImg,
    };
  },
};
</script>

<style lang="scss">
.userbox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .user_title {
    height: 3rem;
    background-color: rgba($color: $color6, $alpha: 0.7);
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    box-shadow: 0 1px 8px -5px #fff;
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
    // overflow: hidden;
  }
  .user_data {
    background-color: rgba($color: $color6, $alpha: 0.6);
    height: calc(100% - 3rem);
    li {
      display: flex;
      align-items: center;
      width: 80%;
      margin: 1rem auto;
      color: #fff;
      min-height: 3rem;
      .avatar {
        margin: 0 auto;
        position: relative;
        p {
          font-size: 2rem;
          width: 4.5rem;
          height: 4.5rem;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          background-color: $color5;
        }
        img {
          width: 4.5rem;
          height: 4.5rem;
          border-radius: 50%;
          object-fit: cover;
        }
        input {
          display: none;
        }
        label {
          width: 1.5rem;
          height: 1.5rem;
          border: none;
          background: url("../assets/avatar_w.svg") no-repeat center center;
          background-size: contain;
          position: absolute;
          right: -2rem;
          bottom: 0;
          cursor: pointer;
        }
      }
      span {
        font-size: 1.2rem;
        width: 30%;
      }
      .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 70%;
        p {
          font-size: 1.2rem;
          margin-right: 1rem;
          word-break: break-all;
        }
        input {
          border: none;
          outline: none;
          background-color: rgba($color: $color6, $alpha: 0);
          color: #fff;
          font-size: 1.2rem;
          text-align: right;
          margin-right: 1rem;
          width: 80%;
        }
        button {
          width: 1.5rem;
          height: 1.5rem;
          border: none;
        }
        .set_name {
          background: url("../assets/setting_w.svg") no-repeat center center;
          background-size: contain;
        }
        .confirm {
          background: url("../assets/tick_w.svg") no-repeat center center;
          background-size: contain;
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .userbox {
    .user_title {
      display: none;
    }
    .user_data {
      height: 100%;
      li {
        .avatar {
          position: static;
          display: flex;
          flex-direction: column;
          align-items: center;
          label {
            margin-top: 0.5rem;
            position: static;
          }
        }
        span {
          display: none;
        }
        .right {
          width: 100%;
          justify-content: center;
          flex-direction: column;
          p {
            margin-right: 0;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}
</style>
