import { defineStore } from "pinia";
import { ref } from "vue";
export const useStore = defineStore("main", () => {
  const me = ref({});
  const friends = ref([]);
  const chat_fid = ref("");
  const friend = ref({});
  const chatList = ref([]);
  const active = ref(4);
  const page = ref(4);
  const isLoading = ref(false);
  const nowTime = ref("");
  const bigImgShow = ref(false);
  const bigImgUrl = ref("");
  const callFriend = ref({});
  const callRole = ref("");
  return {
    me,
    friends,
    chat_fid,
    friend,
    chatList,
    active,
    page,
    isLoading,
    nowTime,
    callFriend,
    bigImgShow,
    bigImgUrl,
    callRole,
  };
});
