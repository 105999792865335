<template>
  <div :class="`my-${msg.style}`" class="mytoast" ref="toastref">
    <div class="mytoast-header">
      <div class="left">
        <span :class="`my-${msg.style}`" class="light"></span>
        <strong> {{ msg.title }}</strong>
      </div>
      <button class="close" @click="closetoast"></button>
    </div>
    <div class="mytoast-body" v-if="msg.content">
      {{ msg.content }}
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
export default {
  props: ["msg"],
  setup() {
    const toastref = ref(null);
    onMounted(() => {
      setTimeout(() => {
        toastref.value.remove();
      }, 6000);
    });
    function closetoast() {
      // toastref.value.style = "opacity:0";
      toastref.value.style = "max-height:0";
      setTimeout(() => {
        toastref.value.remove();
      }, 300);
    }

    return { toastref, closetoast };
  },
};
</script>

<style lang="scss">
.my-success {
  background-color: #198754;
}
.my-danger {
  background-color: #dc3545;
}
.mytoast {
  border-radius: 10px;
  overflow: hidden;
  margin: 0.5rem;
  transition: 0.3s;
  animation: hide 0.3s forwards 5.7s;
  overflow: hidden;
  max-height: 200px;
  .mytoast-header {
    background-color: $color6;
    color: $color1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    .left {
      display: flex;
      align-items: center;
      margin-right: 1rem;
      .light {
        min-width: 1rem;
        min-height: 1rem;
        border-radius: 35%;
      }
      strong {
        margin-left: 0.5rem;
        font-weight: bold;
      }
    }
    .close {
      background: url("../assets/close_w.svg") no-repeat center center;
      background-size: contain;
      width: 1.5rem;
      height: 1.5rem;
      border: none;
    }
  }
  .mytoast-body {
    background-color: $color6;
    color: $color1;
    margin-top: 1px;
    padding: 0.5rem 1rem;
  }
}

@keyframes hide {
  from {
    // opacity: 1;
    max-height: 200px;
  }
  to {
    // opacity: 0;
    max-height: 0;
  }
}
</style>
