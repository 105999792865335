<template>
  <div class="callsharing">
    <div class="callsharing_title">
      <button @click="closeCallRoom"></button>
      <p>{{ username }}</p>
    </div>
    <div class="callsharing_content"></div>
  </div>
</template>

<script>
// import { useSocketIO } from "@/service/socket";
import { inject, ref, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
export default {
  setup() {
    const route = useRoute();
    const id = route.query.id;
    const username = ref("");
    const axios = inject("axios");

    getFriend();
    function getFriend() {
      const api = `${process.env.VUE_APP_API}/api/user/friend/${id}`;
      axios.get(api).then((res) => {
        username.value = res.data.data[0].name;
      });
    }

    const me = reactive({});
    getme();
    async function getme() {
      const api = `${process.env.VUE_APP_API}/api/user/me`;
      let res = await axios.get(api);
      Object.assign(me, res.data.data[0]);
      socketStart();
    }

    function socketStart() {
      //   socket.emit("join-chat", { id: me.id, fid: id });
    }

    const router = useRouter();
    function closeCallRoom() {
      router.push({ name: "call" });
    }

    return {
      username,
      getFriend,
      me,
      closeCallRoom,
    };
  },
};
</script>

<style lang="scss">
.callsharing {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  .callsharing_title {
    height: 3rem;
    background-color: $color6;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    box-shadow: 0 1px 8px -5px #fff;
    z-index: 2;
    position: relative;
    button {
      position: absolute;
      left: 1rem;
      background: url("../assets/arrowleft_w.svg") no-repeat center center;
      background-size: contain;
      width: 2rem;
      height: 2rem;
      border: none;
    }
  }
  .callsharing_content {
    padding: 1rem;
    height: calc(100% - 3rem);
    z-index: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: $color6;
    color: white;
  }
}
</style>
