<template>
  <div class="toastMessage">
    <Toast v-for="(msg, key) in messages" :key="key" :msg="msg"></Toast>
  </div>
</template>

<script>
import Toast from "@/components/ToastComponent.vue";
import { inject, onMounted, reactive } from "vue";
export default {
  components: { Toast },
  setup() {
    const emitter = inject("emitter");
    const messages = reactive([]);
    onMounted(() => {
      emitter.on("push-message", (message) => {
        const { style = "success", title, content } = message;
        messages.push({ style, title, content });
      });
    });

    return { messages };
  },
};
</script>

<style lang="scss">
.toastMessage {
  max-width: 250px;
  position: fixed;
  bottom: 0;
  right: 0;
  margin-bottom: 3rem;
  z-index: 1;
}
</style>
