/*--------------------------
接收一個時間差 現在與創建時的差
 這邊給回傳朋友最後一筆訊息的時間差，簡約時間
 1分鐘內顯示剛剛
 1小時內顯示幾分鐘前
 1天內顯示幾小時前
 1星期內顯示幾天前
 超過一星期顯示 月與日
 超過一年顯示 年與月
----------------------------*/
export function timeGapFormat(timegap) {
  const s = 1000;
  let timeString = "";
  const howMinute = Math.floor(timegap / (60 * s));
  const howHour = Math.floor(timegap / (3600 * s));
  const howDay = Math.floor(timegap / (24 * 3600 * s));
  const howWeek = Math.floor(timegap / (7 * 24 * 3600 * s));
  const howYear = Math.floor(timegap / (365 * 24 * 3600 * s));

  if (!howMinute) {
    timeString = "剛剛";
  } else if (!howHour) {
    timeString = `${howMinute}分鐘前`;
  } else if (!howDay) {
    timeString = `${howHour}小時前`;
  } else if (!howWeek) {
    timeString = `${howDay}天前`;
  } else if (!howYear) {
    const createTime = new Date(Date.now() - timegap);
    let createMonth = createTime.getMonth() + 1;
    let createDay = createTime.getDate();
    if (createMonth < 10) createMonth = parseInt(`0${createMonth}`);
    if (createDay < 10) createDay = parseInt(`0${createDay}`);
    timeString = `${createMonth}-${createDay}`;
  } else if (howYear) {
    const createTime = new Date(Date.now() - timegap);
    let createYear = createTime.getFullYear();
    let createMonth = createTime.getMonth() + 1;
    if (createMonth < 10) createMonth = parseInt(`0${createMonth}`);
    timeString = `${createYear}-${createMonth}`;
  } else {
    timeString = "剛剛";
  }
  return timeString;
}

/*--------------------------
接收創建時間
 這邊給指定朋友回傳所有訊息時間
 同一日顯示 時與分  00:00
 不同日顯示 月與日 時與分  01-01 00:00
 不同年顯示 年與月與日 時與分 2022-01-01 00:00
----------------------------*/
export function timeDateFormat(time) {
  let timeString = "";

  const nowTime = new Date();
  const nowYear = nowTime.getFullYear();
  const nowMonth = nowTime.getMonth() + 1;
  const nowDay = nowTime.getDate();

  const createTime = new Date(time);
  const createYear = createTime.getFullYear();
  let createMonth = createTime.getMonth() + 1;
  let createDay = createTime.getDate();
  let createHour = createTime.getHours();
  let createMinute = createTime.getMinutes();

  if (createMonth < 10) createMonth = `0${createMonth}`;
  if (createDay < 10) createDay = `0${createDay}`;
  if (createHour < 10) createHour = `0${createHour}`;
  if (createMinute < 10) createMinute = `0${createMinute}`;

  //這邊用!=字串跟數字對比
  if (nowYear !== createYear) {
    timeString = `${createYear}-${createMonth}-${createDay} ${createHour}:${createMinute}`;
  } else if (nowMonth != createMonth || nowDay != createDay) {
    timeString = `${createMonth}-${createDay} ${createHour}:${createMinute}`;
  } else {
    timeString = `${createHour}:${createMinute}`;
  }

  return timeString;
}
/* ------------------------
計時器
一小時內為00:00
超過為00:00:00
------------------------ */
export function timing(seconds) {
  const s = 1000;
  let timeString = "";
  let howSecond = Math.floor(seconds / s);
  let howMinute = Math.floor(howSecond / 60);
  let howHour = Math.floor(howMinute / 60);

  let second = howSecond % 60 < 10 ? `0${howSecond % 60}` : `${howSecond % 60}`;
  let mimute = howMinute % 60 < 10 ? `0${howMinute % 60}` : `${howMinute % 60}`;
  let hour = howHour % 60 < 10 ? `0${howHour % 60}` : `${howHour % 60}`;

  if (!howHour) {
    timeString = `${mimute}:${second}`;
  } else {
    timeString = `${hour}:${mimute}:${second}`;
  }
  return timeString;
}
