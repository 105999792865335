<template>
  <div class="callvideo">
    <transition name="call">
      <div class="callvideo_content" v-if="callFriend.id">
        <div class="localVideo">
          <video
            autoplay
            playsinline
            ref="localVideo"
            width="375"
            height="800"
          ></video>
        </div>
        <div class="remoteVideo">
          <video
            autoplay
            platsinline
            ref="remoteVideo"
            width="150"
            height="200"
          ></video>
        </div>
        <div class="content_top" v-if="!isCall">
          <div class="avatar">
            <img
              v-if="callFriend.avatar"
              :src="callFriend.avatar"
              alt="無頭像"
            />
            <h3 v-else>{{ callFriend.name.slice(0, 1) }}</h3>
          </div>
          <h3 class="user_name">{{ callFriend.name }}</h3>
        </div>
        <div class="content_center" v-if="isCall">
          <p class="callTime">{{ callTime }}</p>
        </div>
        <div class="content_bottom">
          <div class="callbtn openAudio" v-if="isInit">
            <img :src="audioImg" @click="toggleAudio" />
          </div>
          <div class="callbtn calloff">
            <img src="@/assets/calloff_w.svg" @click="closeCallRoom" />
          </div>
          <div class="callbtn openVideo">
            <img :src="videoImg" @click="toggleVideo" />
          </div>
          <div class="callbtn callon" v-if="callRole === 'answer' && !isInit">
            <img src="@/assets/callon_w.svg" @click="init" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { useSocketIO } from "@/service/socket";
import { ref, watch, inject } from "vue";
import { useStore } from "@/store/index";
import { storeToRefs } from "pinia";
import { timing } from "@/methods/timeFormat";

export default {
  setup() {
    const emitter = inject("emitter");
    const store = useStore();
    const { callFriend, me, page, active, callRole } = storeToRefs(store);
    let callInterval;
    let startTime;
    const callTime = ref("");

    const videoImg = ref(require("@/assets/videoon_w.svg"));
    const audioImg = ref(require("@/assets/audioon_w.svg"));
    function toggleVideo() {
      videoTrack[0].enabled = videoTrack[0].enabled ? false : true;
      if (videoTrack[0].enabled) {
        videoImg.value = require("@/assets/videoon_w.svg");
      } else {
        videoImg.value = require("@/assets/videooff_w.svg");
      }
    }
    function toggleAudio() {
      audioTrack[0].enabled = audioTrack[0].enabled ? false : true;
      if (audioTrack[0].enabled) {
        audioImg.value = require("@/assets/audioon_w.svg");
      } else {
        audioImg.value = require("@/assets/audiooff_w.svg");
      }
    }
    watch(
      () => callFriend.value,
      async () => {
        if (me.value && Object.keys(callFriend.value).length) {
          if (!socket1) {
            socketStart();
          }
          socketListeing();
          await createStream();
          console.log(localVideo.value.clientHeight);
          startTime = Date.now();
          if (callRole.value === "offer") {
            init();
          }
        }
      }
    );

    const localVideo = ref(null);
    const remoteVideo = ref(null);

    let socket1;
    let peerConn;
    let localstream;
    let videoTrack;
    let audioTrack;
    const isInit = ref(false);
    const isCall = ref(false);
    async function init() {
      initPeerConnection();
      socket1.emit("join-callvideo", { fid: callFriend.value.id });
      isInit.value = true;
    }

    function socketStart() {
      let { socket } = useSocketIO(me.value);
      socket1 = socket;
    }
    function socketListeing() {
      socket1.on("ready", async (msg) => {
        console.log(msg);
        console.log("發送offer");
        await sendSDP(true);
      });
      socket1.on("ice_candidate", async (data) => {
        console.log("收到 ice_candidate");
        const candidate = new RTCIceCandidate({
          sdpMLineIndex: data.label,
          candidate: data.candidate,
        });
        await peerConn.addIceCandidate(candidate);
        isCall.value = true;
        emitter.emit("push-message", {
          style: "success",
          title: "已經接通",
        });
        callTime.value = "00:00";
        callInterval = setInterval(() => {
          callTime.value = timing(Date.now() - startTime);
        }, 1000);
      });
      socket1.on("offer", async (desc) => {
        console.log("收到offer");
        await peerConn.setRemoteDescription(desc);
        await sendSDP(false);
      });
      socket1.on("answer", async (desc) => {
        console.log("收到 answer");
        await peerConn.setRemoteDescription(desc);
      });
      socket1.on("bye", () => {
        if (socket1) {
          socket1.emit("leave-callvideo");
        }
        hangup();
        console.log(callFriend.value.id + "掛斷電話");
        page.value = 4;
        active.value = 4;
        setTimeout(() => {
          callFriend.value = {};
        }, 300);
      });
    }
    function hangup() {
      if (peerConn) {
        peerConn.close();
        peerConn = null;
        emitter.emit("push-message", {
          style: "danger",
          title: "通話已掛斷",
        });
        callRole.value = "";
        isInit.value = false;
        isCall.value = false;
      }
    }
    async function closeCallRoom() {
      if (socket1) {
        socket1.emit("leave-callvideo", { id: callFriend.value.id });
      }
      console.log("結束通話");
      hangup();
      page.value = 4;
      active.value = 4;
      isCall.value = false;
      callTime.value = "";
      clearInterval(callInterval);
      setTimeout(() => {
        isInit.value = false;
        callFriend.value = {};
      }, 300);
      socket1.off("ready");
      socket1.off("ice_candidate");
      socket1.off("offer");
      socket1.off("answer");
      socket1.off("bye");
    }

    async function sendSDP(isOffer) {
      if (!peerConn) {
        return;
      }
      const localSDP = await peerConn[isOffer ? "createOffer" : "createAnswer"](
        {
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        }
      );
      await peerConn.setLocalDescription(localSDP);
      let e = isOffer ? "offer" : "answer";

      socket1.emit(e, peerConn.localDescription);
    }

    async function createStream() {
      localstream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: {
          width: 500,
          height: 900,
        },
      });
      videoTrack = localstream.getVideoTracks();
      audioTrack = localstream.getAudioTracks();
      localVideo.value.srcObject = localstream;
      videoImg.value = require("@/assets/videoon_w.svg");
      audioImg.value = require("@/assets/audioon_w.svg");
      callTime.value = "00:00";
    }
    function initPeerConnection() {
      const configuration = {
        iceServers: [
          {
            urls: "stun:stun.1.google.com:19302",
          },
        ],
      };
      peerConn = new RTCPeerConnection(configuration);
      localstream.getTracks().forEach((track) => {
        peerConn.addTrack(track, localstream);
      });

      peerConn.onicecandidate = (e) => {
        if (e.candidate) {
          console.log("發送 ICE");
          socket1.emit("ice_candidate", {
            label: e.candidate.sdpMLineIndex,
            id: e.candidate.sdpMid,
            candidate: e.candidate.candidate,
          });
        }
      };
      peerConn.onaddstream = ({ stream }) => {
        remoteVideo.value.srcObject = stream;
      };
    }

    return {
      callFriend,
      me,
      closeCallRoom,
      localVideo,
      remoteVideo,
      callTime,
      videoImg,
      audioImg,
      toggleVideo,
      toggleAudio,
      callRole,
      init,
      isInit,
      isCall,
    };
  },
};
</script>

<style lang="scss">
.callvideo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba($color: $color6, $alpha: 0.5);
  overflow: hidden;

  .callvideo_content {
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    transition: 0.3s;
    background-color: rgba($color: #000000, $alpha: 0.9);
    .localVideo {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      video {
        transform: scaleX(-1);
        width: auto;
        height: auto;
        background: none;
      }
    }
    .remoteVideo {
      width: 150px;
      height: 200px;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: -1;
      video {
        transform: scaleX(-1);
        width: auto;
        height: auto;
        background: none;
      }
    }
    .content_top {
      display: flex;
      flex-direction: column;
      align-items: center;
      .avatar {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          width: 6rem;
          height: 6rem;
          border-radius: 50%;
          object-fit: cover;
        }
        h3 {
          width: 6rem;
          height: 6rem;
          color: white;
          border-radius: 50%;
          background-color: $color5;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 2rem;
        }
      }
      .user_name {
        margin-top: 2rem;
        font-weight: bold;
        font-size: 1.5rem;
        color: $color1;
      }
    }
    .content_center {
      .callTime {
        color: $color1;
      }
    }
    .content_bottom {
      margin-top: 2rem;
      width: 100%;
      display: flex;
      justify-content: space-around;
      transition: 0.3s;
      .callbtn {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s;
        img {
          width: 35px;
          height: 35px;
        }
      }
      .calloff {
        background-color: #dc3545;
      }
      .callon {
        background-color: #198754;
      }
      .openVideo {
        background-color: #333;
        border: 3px solid white;
      }
      .openAudio {
        background-color: #333;
        border: 3px solid white;
      }
    }
  }
}
.call-enter-active,
.call-leave-active {
  transform: translateY(100%);
}
.call-enter-to .call-leave-to {
  transform: translateY(100%);
}
</style>
